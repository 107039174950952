@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-style: oblique 0deg 10deg;
    font-weight: 100 900;
    src: url('../public/fonts/inter.woff2') format('woff2');
  }

  @import '@kin/css/external/settings';
  @import '@kin/css/external/generic/reset';
  @import '@kin/css/external/generic/elements';
  @import '@kin/css/external/objects';
  @import '@kin/css/external/components';
  @import '@kin/css/external/utilities/typography';
  @import '@kin/css/external/utilities/colors';
  @import '@kin/css/external/utilities/spacing';
  @import '@kin/css/external/utilities/display';
  @import '@kin/css/external/utilities/layout';

  html {
    scrollbar-gutter: stable;
  }

  /**
    * Layout
    ----------------------------
  */

  .qui-form-layout {
    --columns: 1; // how many columns the layout should have
    --center-col-start: 1; // where you want the center column to start
    --center-col-span: 1; // how many columns the center column should span
    display: grid;
    gap: var(--space-400);
    padding-block-start: var(--space-300);
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    grid-template-rows: auto 1fr;

    // // For tablet sizes and up
    @media (min-width: 834px) {
      padding-block-start: var(--space-800);
    }

    // We set 1250px so that the end rail minimum width does not fall below 229px.
    @media (min-width: 1250px) and (max-width: 1599px) {
      --columns: 5;
      --center-col-start: 2;
      --center-col-span: 3;
    }

    @media (min-width: 1600px) {
      --columns: 4;
      --center-col-start: 2;
      --center-col-span: 2;
    }
  }

  .qui-form-layout__col-alert {
    grid-row-start: 1;
    grid-column: var(--center-col-start) / span var(--center-col-span);
  }

  .qui-form-layout__col-center {
    grid-column: var(--center-col-start) / span var(--center-col-span);
  }

  .qui-form-layout__col-rail {
    grid-column: var(--columns) / span 1;
    grid-row: auto;

    @media (min-width: 992px) {
      grid-row: 1 / -1; /* Spans all rows */

      // Adjust rail card padding slightly
      .card {
        padding: var(--space-400);
      }
    }
  }

  /**
    2:1 Layout
    * Currently Used for forms with a social proof sidebar
    * To update the layout for different breakpoints:
      1. Update the media query in .qui-form-layout:has(.qui-form-layout__two-to-one) --> override --columns, --center-col-start, and --center-col-span variables
      2. Update the media query  .qui-form-layout__two-to-one__col-rail class with the new grid-column and grid-row values
    ----------------------------
  */

  // :has() is used to override in the parent element (.qui-form-layout) --> https://developer.mozilla.org/en-US/docs/Web/CSS/:has
  .qui-form-layout:has(.qui-form-layout__two-to-one) {
    @media (min-width: 992px) and (max-width: 1249px) {
      --columns: 6;
      --center-col-start: 1;
      --center-col-span: 4;
    }

    @media (min-width: 1250px) {
      --columns: 8;
      --center-col-start: 2;
      --center-col-span: 4;
    }
  }

  .qui-form-layout__two-to-one__col-rail {
    @media (min-width: 992px) and (max-width: 1249px) {
      grid-column: 5 / span 2;
    }

    @media (min-width: 1250px) {
      grid-column: 6 / span 2;
    }
  }

  /**
   Kinetic Overrides
   - Temporary kinetic overrides to fix issues until Kinetic is updated
   ----------------------------
  */

  // Can remove after https://github.com/kin/kinetic/issues/490 is resolved
  .switch__control {
    &:focus-within {
      box-shadow: 0 0 0 6px var(--color-primary);
      outline: 2px solid var(--color-neutral-white);
    }
  }

  /**
   * Updated link focus since it is missing on Kinetic right now
   */
  .link {
    &:focus-within {
      box-shadow: 0 0 0 2px var(--color-primary);
      outline: none;
    }
  }

  /**
  * Adds styles for missing readonly radio cards and selects
  * can remove after https://github.com/kin/kinetic/issues/509 is resolved
  */

  .form-group__fieldset[aria-disabled='true'] {
    pointer-events: none;

    .radio-card {
      background: var(--color-neutral-200);
      color: var(--color-neutral-600);
    }

    .radio-card:has(.radio-card__control:checked) {
      background: var(--color-primary-200);
    }
  }

  .select__control[aria-disabled='true'] {
    pointer-events: none;
    cursor: not-allowed;
    background-color: var(--color-neutral-200);
    color: var(--color-neutral-500);
  }
}
