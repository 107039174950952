.pill {
  display: inline-flex;
  padding: var(--space-75) var(--space-100);
  border-radius: var(--border-radius-400);
  background-color: var(--color-neutral-100);
  color: var(--color-neutral-black);
  line-height: var(--line-height-300);
}

.pill__content {
  display: inline;
  font-size: var(--font-size-body-xs);
  font-weight: var(--font-weight-semi-bold);
}

.pill--primary {
  background-color: var(--color-primary);
  color: var(--color-neutral-black);
}

.pill--secondary {
  background-color: var(--color-secondary);
  color: var(--color-neutral-white);
}

.pill--tertiary {
  background-color: var(--color-tertiary);
  color: var(--color-neutral-white);
}

.pill--info {
  background: var(--color-neutral-500);
  color: var(--color-neutral-white);
}

.pill--warning {
  background-color: var(--color-warning);
  color: var(--color-neutral-white);
}

.pill--static {
  background: var(--color-neutral-700);
  color: var(--color-neutral-white);
}

.pill--sm>.pill__content {
  font-size: var(--font-size-body-xs);
}

.pill--md>.pill__content {
  font-size: var(--font-size-body-sm);
}

.pill--lg>.pill__content {
  font-size: var(--font-size-body-md);
}
