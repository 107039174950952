@use 'sass:math';
@use '../../common/settings/grid';
@use '../../common/tools/mixins';

.container {
  @include mixins.container;
}

.container-fluid {
  @include mixins.container($max-width: none);
}

.row {
  @include mixins.row;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.column {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.column.reverse {
  flex-direction: column-reverse;
}

.column-reverse {
  flex-direction: column-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex {
  display: flex;
}

.flex-one {
  flex: 1 1 0;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-none {
  flex: none;
}

.flex-initial {
  flex: initial;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* stylelint-disable sassdoc/atExample, sassdoc/atParameter, sassdoc/mixin-is-documented */
@mixin grid($name) {
  .col-#{$name} {
    max-width: 100%;
    flex: 1 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  @for $i from 1 through grid.$columns {
    $width: math.div(100%, grid.$columns) * $i;

    .col-#{$name}-#{$i} {
      @include mixins.col($max-width: $width);
    }
  }

  @for $i from 0 through grid.$columns - 1 {
    $width: math.div(100%, grid.$columns) * $i;

    .col-#{$name}-offset-#{$i} {
      margin-left: $width;
    }
  }

  .start-#{$name} {
    justify-content: flex-start;
  }

  .center-#{$name} {
    justify-content: center;
  }

  .end-#{$name} {
    justify-content: flex-end;
  }

  .top-#{$name} {
    align-items: flex-start;
  }

  .middle-#{$name} {
    align-items: center;
  }

  .bottom-#{$name} {
    align-items: flex-end;
  }

  .stretch-#{$name} {
    align-items: stretch;
  }

  .baseline-#{$name} {
    align-items: baseline;
  }

  .around-#{$name} {
    justify-content: space-around;
  }

  .between-#{$name} {
    justify-content: space-between;
  }

  .first-#{$name} {
    order: -1;
  }

  .last-#{$name} {
    order: 1;
  }
}

/* stylelint-enable */

@each $name,
  $media-query in grid.$media-query-map {
  @if $name =='xs' {
    @include grid($name);
  }

  @else {
    @include mixins.media-query($name) {
      @include grid($name);
    }
  }
}
