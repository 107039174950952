.button {
  display: inline-flex;
  width: fit-content;
  box-sizing: border-box;
  align-items: center;
  padding: var(--space-300) var(--space-300);
  border: 0;
  border: 1px solid var(--color-neutral-black);
  border-radius: var(--border-radius-200);
  appearance: none;
  background-color: var(--color-primary);
  cursor: pointer;
  font-family: inherit;
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-reset);
  text-align: center;
  text-decoration: none;

  &:focus:not(:disabled) {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &:hover {
    background: var(--color-primary-400);
    text-decoration: none;
  }

  &[disabled],
  .disabled {
    background: var(--color-neutral-300);
    color: var(--color-neutral-black);
    text-decoration: none;
  }

  &[disabled]:hover,
  .disabled:hover {
    background: var(--color-neutral-300);
    color: var(--color-neutral-black);
    cursor: not-allowed;
  }
}

.button__prefix,
.button__prefix::slotted(*) {
  display: inline-flex;
  margin-right: var(--space-100);
}

.button__suffix,
.button__suffix::slotted(*) {
  display: inline-flex;
  margin-left: var(--space-100);
}

.button--block {
  display: block;
  width: 100%;
}

.button--sm {
  padding: var(--space-100) var(--space-200);
  font-size: var(--font-size-body-sm);
}

.button--lg {
  padding: var(--space-300) var(--space-400);
  font-size: var(--font-size-body-lg);
}

.button--primary {
  background: var(--color-primary);
  color: var(--color-neutral-black);
}

.button--secondary {
  border: 1px solid var(--color-neutral-black);
  background: var(--color-neutral-white);
  color: var(--color-neutral-black);

  &:focus:not(:disabled) {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &:hover {
    background: var(--color-primary-400);
  }

  &[disabled],
  .disabled {
    background: var(--color-neutral-300);
    color: var(--color-neutral-black);
    text-decoration: none;
  }

  &[disabled]:hover,
  .disabled:hover {
    background: var(--color-neutral-300);
    color: var(--color-neutral-black);
    cursor: not-allowed;
  }
}

.button--tertiary {
  background: var(--color-blue);
  color: var(--color-neutral-white);
}

.button--tertiary:hover,
.button--tertiary:focus {
  background: var(--color-blue-100);
  color: var(--color-neutral-black);
}

.button--warning {
  background: var(--color-warning);
  color: var(--color-neutral-white);
}

.button--warning:hover,
.button--warning:focus {
  background: var(--color-warning-600);
}

.button--link {
  padding: 0;
  border: 0;
  background: transparent;
  color: var(--color-neutral-black);
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
}

.button--link:hover,
.button--link:focus {
  background-color: transparent;
  color: var(--color-primary-1000);
  text-decoration: underline;
}
