:root {
  --color-primary: var(--color-yellow-600);
  --color-primary-100: var(--color-yellow-100);
  --color-primary-200: var(--color-yellow-200);
  --color-primary-300: var(--color-yellow-300);
  --color-primary-400: var(--color-yellow-400);
  --color-primary-500: var(--color-yellow-500);
  --color-primary-600: var(--color-yellow-600);
  --color-primary-700: var(--color-yellow-700);
  --color-primary-800: var(--color-yellow-800);
  --color-primary-900: var(--color-yellow-900);
  --color-primary-1000: var(--color-yellow-1000);

  --color-secondary: var(--color-secondary-600);
  --color-secondary-100: var(--color-green-100);
  --color-secondary-200: var(--color-green-200);
  --color-secondary-300: var(--color-green-300);
  --color-secondary-400: var(--color-green-400);
  --color-secondary-500: var(--color-green-500);
  --color-secondary-600: var(--color-green-600);
  --color-secondary-700: var(--color-green-700);
  --color-secondary-800: var(--color-green-800);
  --color-secondary-900: var(--color-green-900);
  --color-secondary-1000: var(--color-green-1000);

  --color-tertiary: var(--color-tertiary-600);
  --color-tertiary-100: var(--color-blue-100);
  --color-tertiary-200: var(--color-blue-200);
  --color-tertiary-300: var(--color-blue-300);
  --color-tertiary-400: var(--color-blue-400);
  --color-tertiary-500: var(--color-blue-500);
  --color-tertiary-600: var(--color-blue-600);
  --color-tertiary-700: var(--color-blue-700);
  --color-tertiary-800: var(--color-blue-800);
  --color-tertiary-900: var(--color-blue-900);
  --color-tertiary-1000: var(--color-blue-1000);

  --color-warning: var(--color-warning-600);
  --color-warning-100: var(--color-red-100);
  --color-warning-200: var(--color-red-200);
  --color-warning-300: var(--color-red-300);
  --color-warning-400: var(--color-red-400);
  --color-warning-500: var(--color-red-500);
  --color-warning-600: var(--color-red-600);
  --color-warning-700: var(--color-red-700);
  --color-warning-800: var(--color-red-800);
  --color-warning-900: var(--color-red-900);
  --color-warning-1000: var(--color-red-1000);

  --color-success: var(--color-success-600);
  --color-success-100: var(--color-green-100);
  --color-success-200: var(--color-green-200);
  --color-success-300: var(--color-green-300);
  --color-success-400: var(--color-green-400);
  --color-success-500: var(--color-green-500);
  --color-success-600: var(--color-green-600);
  --color-success-700: var(--color-green-700);
  --color-success-800: var(--color-green-800);
  --color-success-900: var(--color-green-900);
  --color-success-1000: var(--color-green-1000);

  --color-neutral-white: #ffffff;
  --color-neutral-black: #12120f;
  --color-neutral-100: #fbfbfb;
  --color-neutral-200: #eeeeee;
  --color-neutral-300: #dadada;
  --color-neutral-400: #bebebd;
  --color-neutral-500: #9c9c9a;
  --color-neutral-600: #747471;
  --color-neutral-700: #50504c;
  --color-neutral-800: #3d3d38;
  --color-neutral-900: #282823;
  --color-neutral-1000: #12120f;

  --color-yellow-100: #fffaf3;
  --color-yellow-200: #fff5e7;
  --color-yellow-300: #ffebcd;
  --color-yellow-400: #ffe0af;
  --color-yellow-500: #ffd48a;
  --color-yellow-600: #ffc857;
  --color-yellow-700: #eaad46;
  --color-yellow-800: #b86c1c;
  --color-yellow-900: #ae5f13;
  --color-yellow-1000: #9a4503;

  --color-red-100: #f8f2f2;
  --color-red-200: #e9d6d6;
  --color-red-300: #d9b6b6;
  --color-red-400: #c78e8e;
  --color-red-500: #b45656;
  --color-red-600: #aa1e1f;
  --color-red-700: #981b1c;
  --color-red-800: #841718;
  --color-red-900: #6c1314;
  --color-red-1000: #470d0d;

  --color-green-100: #f2f5f3;
  --color-green-200: #d6dfd8;
  --color-green-300: #b6c6ba;
  --color-green-400: #8fab96;
  --color-green-500: #598965;
  --color-green-600: #277540;
  --color-green-700: #236939;
  --color-green-800: #1e5b32;
  --color-green-900: #194a28;
  --color-green-1000: #10311b;

  --color-blue-100: #f2f4fb;
  --color-blue-200: #d6def4;
  --color-blue-300: #b6c4ed;
  --color-blue-400: #8fa7e5;
  --color-blue-500: #5883dd;
  --color-blue-600: #266dd9;
  --color-blue-700: #2261c2;
  --color-blue-800: #1d54a8;
  --color-blue-900: #184589;
  --color-blue-1000: #102e5b;

  --color-plum-100: #f5f2f4;
  --color-plum-200: #e1d6db;
  --color-plum-300: #cbb5bf;
  --color-plum-400: #b28d9e;
  --color-plum-500: #955375;
  --color-plum-600: #841559;
  --color-plum-700: #761350;
  --color-plum-800: #661045;
  --color-plum-900: #530d38;
  --color-plum-1000: #370925;

  --color-orange-100: #fdf3f2;
  --color-orange-200: #f8dad7;
  --color-orange-300: #f3beb7;
  --color-orange-400: #ee9d90;
  --color-orange-500: #ea735a;
  --color-orange-600: #e7562b;
  --color-orange-700: #cf4d26;
  --color-orange-800: #b34321;
  --color-orange-900: #92361b;
  --color-orange-1000: #612412;

  --link-color: var(--color-neutral-black);
  --link-color-hover: var(--color-primary-1000);
  --link-color-active: var(--color-primary-1000);
  --link-color-visited: var(--color-primary-1000);
  --link-color-focus: var(--color-primary-1000);

}
